<template>
  <!-- 网页标签标题 -->
  <div v-wechat-title="$route.meta.title"></div>
  <!-- 页面顶部 -->
  <a-input
    v-model:value="selectCommentFrom.comment_content"
    placeholder="请输入评论内容"
    class="selectInput"
    :allowClear="true"
    @change="$refs.table.getTableData()"
  />
  <a-button
    class="selectButtom"
    @click="$refs.table.getTableData()"
  >搜 索</a-button>
  <!-- 表格 -->
  <Table
    :columns="columns"
    :getData="selectArticleComment"
    :subHeight=215
    childrenColumnName="child_article_comment"
    ref="table"
  >
    <!-- column表头标题  text展示文字  record后端返回数据  -->
    <template v-slot="{ column, record }">
      <!-- 操作列 -->
      <template v-if="column.dataIndex == 'action'">
        <a-popconfirm
          title="确认删除吗？"
          ok-text="确认"
          cancel-text="取消"
          @confirm="deleteArticleComment(record.id)"
        >
          <a-button
            type="link"
            size="small"
          >删除</a-button>
        </a-popconfirm>
      </template>
      <!-- 标题列 -->
      <template v-if="column.dataIndex == 'article_title'">
        <template v-if="record.article_title == null">
          -
        </template>
        <template v-else>
          《{{record.article_title}}》
        </template>
      </template>
      <!-- 被回复列 -->
      <template v-if="column.dataIndex == 'second_father_user_nickname'">
        <template v-if="record.second_father_user_nickname == null">
          -
        </template>
        <template v-else>
          @{{record.second_father_user_nickname}}
        </template>
      </template>
    </template>
  </Table>
</template>

<script>
import { ref, reactive } from '@vue/reactivity'
import Table from '../components/Table.vue'
import { SELECTARTICLECOMMENT, DELETEARTICLECOMMENT } from '../api/api'
import { onMounted } from '@vue/runtime-core'
export default {
  components: {
    Table
  },
  setup() {
    const table = ref(null)
    onMounted(() => {
      table.value.getTableData()
    })
    // 表格字段
    const columns = ref([
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        align: 'center'
      },
      {
        title: '文章',
        dataIndex: 'article_title',
        key: 'article_title',
        width: 200,
        align: 'center',
        ellipsis: true
      },
      {
        title: '评论用户',
        dataIndex: 'user_nickname',
        key: 'user_nickname',
        width: 120,
        align: 'center',
        ellipsis: true
      },
      {
        title: '被回复用户',
        dataIndex: 'second_father_user_nickname',
        key: 'second_father_user_nickname',
        width: 120,
        align: 'center',
        ellipsis: true
      },
      {
        title: '评论内容',
        dataIndex: 'comment_content',
        key: 'comment_content',
        width: 400,
        ellipsis: true
      },
      {
        title: 'IP',
        dataIndex: 'ip',
        key: 'ip',
        width: 120,
        align: 'center'
      },
      {
        title: '城市',
        dataIndex: 'address_city',
        key: 'address_city',
        width: 120,
        align: 'center'
      },
      {
        title: '创建时间',
        dataIndex: 'create_time_toDate',
        isEdit: false,
        key: 'create_time_toDate',
        width: 200,
        align: 'center'
      },
      {
        title: '操作',
        dataIndex: 'action',
        isEdit: false,
        key: 'action',
        width: 100,
        align: 'center'
      }
    ])
    // 查询数据表单
    const selectCommentFrom = reactive({
      comment_content: ''
    })
    // 查询数据方法
    const selectArticleComment = (pageData) => {
      return new Promise((resolve, reject) => {
        SELECTARTICLECOMMENT(Object.assign(pageData, selectCommentFrom))
          .then((response) => {
            resolve(response.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
    // 删除数据方法
    const deleteArticleComment = (id) => {
      DELETEARTICLECOMMENT({ id }).then((response) => {
        if (response.code === 0) {
          table.value.getTableData()
        }
      })
    }

    return {
      table,
      selectCommentFrom,
      columns,
      selectArticleComment,
      deleteArticleComment
    }
  }
}
</script>

<style scoped>
.selectInput {
  margin-bottom: 8px;
  width: 250px;
}

.selectButtom {
  margin-left: 8px;
}
</style>
